<template>
  <div class="search-bar search-bar--negative-section-margin theme-dark">
    <div class="search-bar__inner">
      <div class="search-bar__description">
        {{ $textDictionary['downloadCenterSoftware.searchDescription'] ?? '' }}
      </div>
      <div class="search-bar__input">
        <div
          v-if="!store.softwareDownloads?.filters"
          style="display: flex; gap: 8px; flex-direction: column"
        >
          <LoadingSkeleton class="search-bar-loading" />
        </div>
        <PrimeVueForm
          v-else
          :id="searchFormId"
          class="p-search-box-form"
          @submit="submitSearchForm()"
        >
          <PrimeVueIconField>
            <PrimeVueInputText
              id="search-box-value"
              v-model="store.query"
              type="search"
              rounded
              name="search-value"
              size="large"
              autocomplete="off"
              :placeholder="
                $textDictionary['softwareCenter.searchPlaceholder'] ?? ''
              "
              class="w-100 p-search-box"
              @update:model-value="clearSarchTerm()"
            >
            </PrimeVueInputText>
            <PrimeVueInputIcon @click="submitSearchForm()">
              <icon :name="'action_bar_magnifier'" size="small" color="white" />
            </PrimeVueInputIcon>
          </PrimeVueIconField>
        </PrimeVueForm>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDownloadStore } from '~/stores/downloadStore/softwareDownloadStore';
import { Icon, LoadingSkeleton } from '@hypercodestudio/basler-components';
const route = useRoute();
const router = useRouter();

const { $textDictionary, $analytics } = useNuxtApp();
const searchFormId = useId();

const store = useDownloadStore();

onMounted(() => {
  if (route.query.query) {
    store.query = route.query.query.toString();
  }
});
function submitSearchForm() {
  store.fetchSoftwareDownloads();

  router.push({
    query: {
      ...route.query,
      query: store.query
    }
  });

  $analytics?.pushToDataLayer({
    action: 'software_search',
    category: 'software',
    event: 'search-software',
    label: store.query
  });
}
function clearSarchTerm() {
  if (isEmpty(store.query)) {
    router.replace({
      query: store.filters,
      replace: true
    });
    store.fetchSoftwareDownloads();
  }
}
</script>

<style>
.search-bar-loading {
  width: 100%;
  border-radius: 100px;
  height: 48px;
}
@media (min-width: 1024px) {
  .search-bar-loading {
    height: 88px;
  }
}
</style>
